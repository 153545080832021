import React, {useState} from 'react';

// RTK Queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetBusinessExpenseReportQuery} from '@compt/app/services/api/business-expense-reports-slice';
import {
  BusinessExpenseReviewPayload,
  useGetAdminBusinessExpensesQuery,
  useBulkUpdateBusinessExpensesMutation,
  useUnapproveBusinessExpenseMutation,
} from '@compt/app/services/api/admin-business-expenses-slice';
import {useUpdateBusinessExpenseMutation} from '@compt/app/services/api/business-expenses-slice';

// Hooks and methods
import {AdminBETableController} from './admin-business-expense-page.controller';
import {Link, useParams} from 'react-router-dom';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {useHasRequiredRole} from '@compt/utils/permission-helpers';
import {getCorrectApprovalStatus} from '@compt/pages/admin-pages/admin-business-expenses/helpers';

// Components
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {ComptPill, ComptPillSize} from '@compt/common/forms/compt-pill/compt-pill';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptRow} from '@compt/common/compt-row/compt-row';
import {ReportFooterText} from '@compt/pages/business-expenses/business-expense-page/components/report-page-footer-text';
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptTable} from '@compt/common/compt-table/compt-table.container';
import {RejectBusinessExpenseModal} from './components/reject-business-expense-modal';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {AdminBusinessExpenseSidePanel} from './components/admin-business-expense-side-panel';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';

// Types
import {BusinessExpense} from '@compt/types/business-expenses/business-expense';
import {BusinessExpenseStatus} from '@compt/types/business-expenses/business-expense-statuses';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {
  BusinessExpenseReportStatus,
  expenseReportStatusFormats,
} from '@compt/types/business-expenses/business-expense-report-statuses';

const controller = AdminBETableController;

export const AdminBusinessExpensePage = () => {
  const {reportId} = useParams();

  const [selectedExpense, setSelectedExpense] = useState<BusinessExpense | null>(null);
  const [expenseToReject, setExpenseToReject] = useState<BusinessExpense | null>(null);
  const [editMode, setEditMode] = useState(false);
  // States necessary to manage and clear selected rows within table
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleClearRows, setToggleClearRows] = useState(false);

  const isMobileView = useIsMobileView();

  const reportQuery = useGetBusinessExpenseReportQuery(reportId ?? skipToken);
  const reportInfo = reportQuery.data;
  const showApprovalButton =
    reportInfo?.status !== BusinessExpenseReportStatus.REVIEWED &&
    reportInfo?.status !== BusinessExpenseReportStatus.REJECTED &&
    reportInfo?.status !== BusinessExpenseReportStatus.PROCESSED;

  const sessionQuery = useGetSessionQuery();
  const isFinancialReviewer = sessionQuery.data?.roles.includes(USER_ROLES.financeReviewer);
  const hasRequiredRole = useHasRequiredRole([
    USER_ROLES.financeReviewer,
    USER_ROLES.employeeManager,
  ]);

  const userId = sessionQuery.data?.user_id;
  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const company = companyQuery.data;
  const businessExpensesQuery = useGetAdminBusinessExpensesQuery(
    {
      companyId: company?.id,
      reportId: reportId,
    },
    {skip: !company?.id || !reportId},
  );
  const expenseList = businessExpensesQuery.data;
  const expenseListExists = !!expenseList?.results && expenseList?.results.length > 0;

  const [bulkUpdateBusinessExpenses, {isLoading}] = useBulkUpdateBusinessExpensesMutation();
  const [updateBusinessExpense, {isLoading: isBEUpdateLoading}] =
    useUpdateBusinessExpenseMutation();
  const [unapproveBusinessExpense] = useUnapproveBusinessExpenseMutation();

  const isTableSelectable =
    reportInfo?.status &&
    (reportInfo?.status === BusinessExpenseReportStatus.SUBMITTED ||
      reportInfo?.status === BusinessExpenseReportStatus.REVIEWED_BY_MANAGER);
  const columnDefinition = controller.getColumnDefinition(
    onActionMenuClicked,
    !!isFinancialReviewer,
  );
  const rowDisabledCriteria = (row: BusinessExpense) =>
    row.status === BusinessExpenseStatus.APPROVED ||
    row.status === BusinessExpenseStatus.REJECTED ||
    (!isFinancialReviewer && row.status === BusinessExpenseStatus.APPROVED_BY_MANAGER);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleSelections({selectedRows}: {selectedRows: any}) {
    setSelectedRows(selectedRows);
  }

  function onActionMenuClicked(expense: BusinessExpense, action: 'VIEW' | 'APPROVE' | 'REJECT') {
    if (!expense) return;
    switch (action) {
      case 'VIEW':
        setSelectedExpense(() => expense);
        break;
      case 'APPROVE':
        handleExpenseStatusUpdate(
          expense.id,
          getCorrectApprovalStatus(expense, isFinancialReviewer),
        );
        break;
      case 'REJECT':
        setExpenseToReject(() => expense);
        break;
    }
  }

  function handleExpenseStatusUpdate(
    id: BusinessExpense['id'],
    action: BusinessExpenseStatus,
    rejection_reason?: string,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const submission: any = {
      id: id,
      status: action,
    };

    if (rejection_reason) {
      submission['rejection_reason'] = rejection_reason;
    }

    updateBusinessExpense(submission).then((data) => {
      if ('error' in data) {
        triggerCustomToast('error', 'Error updating business expense');
        return;
      }
      triggerCustomToast('success', 'Successfully updated business expense');
      if (expenseToReject) {
        setExpenseToReject(() => null);
      }
      // Remove the updated expense from selected rows
      const filteredRows = selectedRows.filter((expense: BusinessExpense) => expense.id !== id);
      setSelectedRows(() => filteredRows);
      setToggleClearRows((prevState) => !prevState);
    });
  }

  function handleUnapproveStatusUpdate(selectedExpense: BusinessExpense) {
    const submission = {
      business_expense: selectedExpense,
    };

    unapproveBusinessExpense(submission).then((results) => {
      if ('error' in results) {
        triggerCustomToast('error', 'Error unapproving report');
        return;
      }

      triggerCustomToast('success', 'Successfully unapproved report');

      // Remove the updated expense from selected rows
      const filteredRows = selectedRows.filter(
        (expense: BusinessExpense) => expense.id !== selectedExpense.id,
      );
      setSelectedRows(() => filteredRows);
      setToggleClearRows((prevState) => !prevState);
    });
  }

  function handleBulkExpenseUpdate(
    expensesToUpdate: BusinessExpense[],
    status: BusinessExpenseStatus,
  ) {
    const submission: BusinessExpenseReviewPayload = {
      business_expense_ids: expensesToUpdate.map((expense: BusinessExpense) => expense.id),
      status: status,
    };

    bulkUpdateBusinessExpenses(submission).then((data) => {
      if ('error' in data) {
        triggerCustomToast('error', 'Error updating report');
        return;
      }
      triggerCustomToast('success', 'Successfully updated report');
      setSelectedRows(() => []);
      setToggleClearRows((prevState) => !prevState);
    });
  }

  function handleApproveSelected() {
    if (selectedRows.length === 0) return;

    const nonApprovedExpenses = selectedRows.filter(
      (expense: BusinessExpense) =>
        expense.status !== BusinessExpenseStatus.APPROVED &&
        expense.status !== BusinessExpenseStatus.APPROVED_BY_MANAGER,
    );

    const approvedByManagerExpenses = selectedRows.filter(
      (expense: BusinessExpense) => expense.status === BusinessExpenseStatus.APPROVED_BY_MANAGER,
    );

    if (isFinancialReviewer && approvedByManagerExpenses.length > 0) {
      handleBulkExpenseUpdate(approvedByManagerExpenses, BusinessExpenseStatus.APPROVED);
    }

    if (nonApprovedExpenses.length > 0) {
      handleBulkExpenseUpdate(nonApprovedExpenses, BusinessExpenseStatus.APPROVED_BY_MANAGER);
    }
  }

  if (
    sessionQuery.isError ||
    companyQuery.isError ||
    businessExpensesQuery.isError ||
    !hasRequiredRole
  ) {
    return <Error404Page />;
  }

  if (!reportInfo || businessExpensesQuery.isLoading || companyQuery.isLoading)
    return <ComptLoadingIndicator isLoading={true} />;

  const {statusStyle, statusName} = expenseReportStatusFormats[reportInfo?.status];

  return (
    <>
      <AdminBusinessExpenseSidePanel
        selectedExpense={selectedExpense}
        setSelectedExpense={setSelectedExpense}
        editMode={editMode}
        setEditMode={setEditMode}
        expenseList={businessExpensesQuery.data?.results ?? []}
        setExpenseToReject={setExpenseToReject}
        handleExpenseStatusUpdate={handleExpenseStatusUpdate}
        handleUnapproveStatusUpdate={handleUnapproveStatusUpdate}
      />
      <RejectBusinessExpenseModal
        expenseToReject={expenseToReject}
        setExpenseToReject={setExpenseToReject}
        handleExpenseStatusUpdate={handleExpenseStatusUpdate}
        isUpdating={isBEUpdateLoading}
      />
      <ComptPage
        title={reportInfo?.title}
        subtitle={reportInfo?.description}
        statusPill={
          <>
            {!isMobileView && (
              <ComptPill className="mb-1" pillSize={ComptPillSize.MEDIUM} pillType={statusStyle}>
                {statusName}
              </ComptPill>
            )}
          </>
        }
        header={
          <div className="flex flex-row gap-3 mb-4 items-center">
            <Link to="/review-business-expenses">
              <p className="label4 text-color-link">Expense reports</p>
            </Link>
            <ComptSvgIcon iconName="chevron-right-icon-gray" />
            <p className="label4 text-color-body1">{reportInfo?.title}</p>
          </div>
        }
        includeBottomHR={false}
        action={
          <>
            {showApprovalButton && !isMobileView && (
              <ComptButton
                buttonType={ComptButtonType.SECONDARY}
                size={ComptButtonSize.SMALL}
                className={`block ${isMobileView && 'w-full my-200'}`}
                onClick={handleApproveSelected}
                disabled={
                  !expenseListExists || selectedRows.length === 0 || isLoading || isBEUpdateLoading
                }
              >
                Approve selected
              </ComptButton>
            )}
          </>
        }
      >
        <>
          {company && expenseList && (
            <ComptRow className={`${isMobileView && 'mt-300'}`}>
              <ComptTable
                company={company}
                tableId={`admin-business-expense-table-${reportInfo.id}`}
                className="w-full"
                data={expenseList.results}
                dataLoading={businessExpensesQuery.isLoading}
                noDataTitleText="No expenses have been added yet"
                noDataSubtitle={
                  <p className="body1 text-center">
                    You haven&apos;t added any expenses yet. Click on &quot;Add Expense&quot; to get
                    started!
                  </p>
                }
                allowShowHide={false}
                columnDefinition={columnDefinition}
                allowPagination={false}
                totalCount={expenseList.count}
                onRowClicked={(row: BusinessExpense) => {
                  setSelectedExpense(() => row);
                }}
                selectableRows={isTableSelectable}
                onSelectedRowsChange={handleSelections}
                selectableRowDisabled={rowDisabledCriteria}
                clearSelectedRows={toggleClearRows}
                fixedHeader={false}
                stickyLastColumn
              />
            </ComptRow>
          )}
          <ComptRow>
            <ReportFooterText
              reportInfo={reportInfo}
              userCountryCode={sessionQuery.data?.country as SupportedCountriesType}
              expenseListExists={expenseListExists}
              forReview
            />
          </ComptRow>
        </>
      </ComptPage>
      {isMobileView && showApprovalButton && (
        <div
          className={`fixed bottom-0 bg-white left-1/2 transform z-10
              -translate-x-1/2 w-full p-300 border-t border-t-gray-300`}
        >
          <ComptButton
            buttonType={ComptButtonType.SECONDARY}
            disabled={selectedRows.length === 0 || isLoading || isBEUpdateLoading}
            data-testid="compt-admin-approve-be-button"
            onClick={handleApproveSelected}
            className="w-full"
          >
            Approve selected
          </ComptButton>
        </div>
      )}
    </>
  );
};
