import React from 'react';

// Types
import {EmployeePreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';
import {
  RequestConfiguration,
  RequestField,
  RequestFieldType,
} from '@compt/types/learning-development/learning-development-program';

// Hooks and methods
import {formatCurrency} from '@compt/utils/international-helpers';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';

interface RequestDataTableProps {
  configurationFields: RequestConfiguration['request_fields'] | null;
  data: EmployeePreApprovalRequest['request_data'];
  currency: EmployeePreApprovalRequest['currency'];
}
export const RequestDataTable = ({configurationFields, data, currency}: RequestDataTableProps) => {
  if (!configurationFields) return null;

  const sortedConfiguration = [...configurationFields].sort((a, b) => a.sequence - b.sequence);

  return (
    <table className="table-auto border-collapse mb-6">
      <thead>
        <th className="text-left label3 text-color-body1">Items</th>
        <th className="text-left label3 text-color-body1">Selection</th>
      </thead>
      <tbody>
        {sortedConfiguration.map((field, index) => {
          if (
            field.field_name === 'Upload document(s)' ||
            field.field_name === 'Upload document(s)_url'
          )
            return <></>;

          return (
            <RequestDataRow
              key={field.id}
              field={field}
              fieldValue={data[field.field_name]}
              currency={currency}
            />
          );
        })}
      </tbody>
    </table>
  );
};

interface RequestDataRowProps {
  field: RequestField;
  fieldValue: string | number;
  currency: EmployeePreApprovalRequest['currency'];
}

const RequestDataRow = (props: RequestDataRowProps) => {
  const {field, fieldValue, currency} = props;
  function getFormattedFieldValue() {
    if (!fieldValue) return '-';

    if (field.field_type === RequestFieldType.CURRENCY) {
      return formatCurrency(fieldValue, currency);
    }

    if (field.field_type === RequestFieldType.DATE && typeof fieldValue === 'string') {
      return formattedDate(fieldValue, DATE_FORMAT_OPTION['mm/dd/yyyy']);
    }

    return fieldValue;
  }

  return (
    <tr className="border-b">
      <td className="py-2 pr-2 body2 text-left align-top w-1/3 break-words">{field.field_name}</td>
      <td className="py-2 body2 text-left break-words">{getFormattedFieldValue()}</td>
    </tr>
  );
};
