import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';

// RTK queries
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useUpdateStipendExpenseMutation} from '@compt/app/services/api/stipend-expenses-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {stripImageNameFromS3URL} from '@compt/utils/image-helpers';
import {formatCurrency} from '@compt/utils/international-helpers';
import {calculateOverBudget} from '@compt/utils/lnd-budget-helpers';

// Types
import {DateString} from '@compt/types/common/date-string';
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';
import {ExpenseStatus} from '@compt/types/stipend-expense';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptReceiptDisplayCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-display-carousel';
import {ComptReceiptFormCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-form-carousel';
import {ComptRejectionReason} from '@compt/common/compt-rejection-reason/compt-rejection-reason';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptCurrencyField} from '@compt/common/forms/compt-currency-field/compt-currency-field';
import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';
import {RejectPreApprovalRequestModal} from './reject-pre-approval-request-modal';
import {ComptDropDownField} from '@compt/common/forms/compt-dropdown-field/compt-dropdown-field';

interface ReviewReimbursementFieldValues {
  employee_name: string;
  vendor: string;
  amount_field: number;
  completion_date: DateString;
  receipt_key: string;
  description_reason: string;
  rejection_reason: string;
  tax_categorization: TaxCategoryOption;
}

interface ReviewReimbursementFormProps {
  request: PreApprovalRequest | undefined;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  onPreviousClick: () => void;
  onNextClick: () => void;
  previousDisabled: boolean;
  nextDisabled: boolean;
  programCurrency: string;
}

export const ReviewReimbursementForm = (props: ReviewReimbursementFormProps) => {
  const {request, editMode} = props;
  const [, setSearchParams] = useSearchParams();

  const [openRejectModal, setOpenRejectModal] = useState(false);

  const session = useGetSessionQuery();
  const [updateExpense, {isLoading: isExpenseUpdating}] = useUpdateStipendExpenseMutation();

  const isReadOnly = !editMode;
  const hasEditableStatus = request?.status === LearningRequestStatus.REIMBURSEMENT_REQUESTED;

  const formMethods = useForm<ReviewReimbursementFieldValues>();
  const watchedDescriptionReason = formMethods.watch('description_reason');
  const watchedTaxCategorization = formMethods.watch('tax_categorization');

  const watchedAmount = formMethods.watch('amount_field');
  const overBudgetCalculation = calculateOverBudget(request?.request_data.Amount, watchedAmount);
  const isOverBudget = overBudgetCalculation > 0;

  // Clear tax categorization error if valid option is selected
  useEffect(() => {
    const taxCategorizationErrorExists = !!formMethods.formState.errors.tax_categorization;

    if (!!watchedTaxCategorization && taxCategorizationErrorExists) {
      formMethods.clearErrors('tax_categorization');
    }
  }, [watchedTaxCategorization]);

  // Reset form with existing data when it loads
  useEffect(() => {
    if (!request || !request.expense) return;
    const reimbursement = request.expense;

    const amount = +reimbursement?.amount_of_expense;

    const isTaxCategorySet = request.reimbursement_request?.is_tax_category_set;
    const selectedTaxCategory = taxCategoryOptions.find(
      (option) => option.id === reimbursement.perk_category,
    );

    formMethods.reset({
      employee_name: request.user.full_name,
      vendor: reimbursement?.vendor_name,
      amount_field: amount,
      receipt_key: reimbursement?.receipt_image,
      completion_date: reimbursement?.date_of_expense,
      description_reason: reimbursement?.description,
      tax_categorization: isTaxCategorySet ? selectedTaxCategory : undefined,
    });
  }, [request]);

  function onSubmit(form: ReviewReimbursementFieldValues) {
    if (!request?.expense) {
      triggerCustomToast('error', 'There is no reimbursement associated with this request.');
      return;
    }
    const reimbursement = request.expense;
    const submission = {
      id: reimbursement.id,
      allotment: request.allotment,
      receipt_key: stripImageNameFromS3URL(form.receipt_key),
      date_of_expense: form.completion_date as DateString,
      vendor: form.vendor,
      description: form.description_reason,
      amount_of_expense: form.amount_field,
      perk_category: form.tax_categorization.id,
    };

    updateExpense({expense: submission, isTaxCategorySet: true}).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem updating this request',
          'Please try again',
        );
        return;
      }

      triggerCustomToast('success', 'Successfully updated this request');
      props.setEditMode(false);
    });
  }

  async function onClickApprove() {
    if (!request?.expense) {
      triggerCustomToast('error', 'There is no reimbursement associated with this request');
      return;
    }

    // Check if tax categorization is populated
    const formValid = await formMethods.trigger();
    if (!formValid) return;

    const reimbursement = request.expense;
    const submission = {
      allotment: request.allotment,
      id: reimbursement.id,
      status: ExpenseStatus.Approved,
      perk_category: watchedTaxCategorization.id,
    };

    updateExpense({expense: submission, isTaxCategorySet: true}).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem approving this request',
          'Please try again',
        );
        return;
      }

      triggerCustomToast('success', 'Successfully approved this request');
      props.setEditMode(false);
    });
  }

  function onClickReject(
    request: PreApprovalRequest,
    action: LearningRequestStatus,
    rejection_reason: string,
  ) {
    if (!request?.expense) {
      triggerCustomToast('error', 'There is no reimbursement associated with this request');
      return;
    }

    const reimbursement = request.expense;
    const submission = {
      allotment: request.allotment,
      id: reimbursement.id,
      status: ExpenseStatus.Rejected,
      rejection_reason: rejection_reason,
    };

    updateExpense({expense: submission}).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem updating this request',
          'Please try again',
        );
        return;
      }

      triggerCustomToast('success', 'Successfully rejected this request');
      props.setEditMode(false);
    });
  }

  const TaxCategorySubLabel = () => {
    if (!window.TAXABILITY_CHECKER_URL) return null;

    return (
      <span className="pb-2">
        <p className="body3 text-color-body2">
          If you’re unsure regarding this request’s tax rules, try our{' '}
          <a
            className="label3 text-color-link cursor-pointer"
            href={window.TAXABILITY_CHECKER_URL}
            target="_blank"
            rel="noreferrer"
          >
            Tax Checker
          </a>{' '}
          to determine it.
        </p>
      </span>
    );
  };

  return (
    <>
      <RejectPreApprovalRequestModal
        isOpen={openRejectModal}
        setIsOpen={setOpenRejectModal}
        requestToReject={request}
        handleRequestStatusUpdate={onClickReject}
        isUpdating={isExpenseUpdating}
      />
      {request?.expense && (
        <>
          <ComptSidePanel.Content>
            <div className="h-full flex flex-col justify-between">
              <form className="grow" onSubmit={formMethods.handleSubmit(onSubmit)}>
                <fieldset className="flex h-full grow">
                  <div className="flex flex-col w-full md:flex-row">
                    <div className="flex justify-center bg-surface-tint sm:overflow-y-clip">
                      <div className="flex flex-col w-full sm:w-[480px] items-end p-6">
                        {request &&
                          request.request_data['Upload document(s)'] &&
                          !props.editMode && (
                            <ComptReceiptDisplayCarousel
                              key={`document-display-for-${request.id}`}
                              documents={
                                request?.expense?.receipt_image
                                  ? [request?.expense?.receipt_image]
                                  : []
                              }
                            />
                          )}
                        {props.editMode && (
                          <ComptReceiptFormCarousel
                            formMethods={formMethods}
                            receiptLabel="Uploaded attachments"
                            userId={session.data?.user_id}
                            // TODO: Remove when backend supports additional attachments COMPT-5750
                            maxNumberOfSupportingDocs={0}
                            initialReceiptValue={request?.expense?.receipt_image}
                            receiptTestDataId="uploaded-document-key-field"
                            receiptDomain="learning_and_dev"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full grow-[2] p-6">
                    {request?.expense?.rejection_reason && (
                      <ComptRejectionReason rejectionReason={request.expense.rejection_reason} />
                    )}
                    <p className="body2 mb-1">Submitted on</p>
                    <p className="body1 mb-400">
                      {formattedDate(
                        request.expense?.date_of_expense,
                        DATE_FORMAT_OPTION['month dd yyyy'],
                      )}
                    </p>
                    <p className="body2 mb-1">Program</p>
                    <p className="body1 mb-400">{request?.program.name}</p>
                    <ComptDropDownField
                      name="tax_categorization"
                      data-testid="tax_categorization"
                      label="Tax categorization"
                      placeholder="Please select tax category"
                      subLabel={<TaxCategorySubLabel />}
                      validation={{required: 'Tax categorization is required'}}
                      options={taxCategoryOptions}
                      getKey={(option) => option.id}
                      getDisplayText={(option) => option.label}
                      getSecondaryText={(option) => option?.secondaryText ?? ''}
                      register={formMethods.register}
                      control={formMethods.control}
                      errors={formMethods.formState.errors.tax_categorization}
                      disabled={!hasEditableStatus}
                    />
                    {/* TODO: refactor to account for custom fields in COMPT-5731 */}
                    <ComptTextField
                      name="employee_name"
                      label="Employee name"
                      control={formMethods.control}
                      register={formMethods.register}
                      readOnly
                      disabled
                    />
                    <ComptTextField
                      name="vendor"
                      label="Vendor"
                      control={formMethods.control}
                      register={formMethods.register}
                      readOnly={isReadOnly}
                      disabled={isReadOnly}
                      validation={{required: 'Vendor is required'}}
                      errors={formMethods.formState.errors.vendor}
                    />
                    <ComptCurrencyField
                      label="Amount covered"
                      name="amount_field"
                      control={formMethods.control}
                      register={formMethods.register}
                      readOnly={isReadOnly}
                      disabled={isReadOnly}
                      validation={{
                        required: 'Amount is required',
                        min: 0.01,
                      }}
                      placeholder="0.00"
                      errors={formMethods.formState.errors.amount_field}
                      givenCurrency={props.programCurrency}
                    />
                    <p className="mb-4">
                      The max amount pre-approved is{' '}
                      {formatCurrency(request.request_data.Amount, props.programCurrency)}.
                    </p>
                    {isOverBudget && (
                      <p className="mb-4 text-color-error">
                        The amount covered exceeds the max amount pre-approved. Please adjust the
                        amount covered accordingly.
                      </p>
                    )}
                    {/* TODO: display User entered, Available budget, over budget in COMPT-5728 */}
                    <ComptDatePickerField
                      name="completion_date"
                      label="Completion date"
                      control={formMethods.control}
                      register={formMethods.register}
                      disabled={isReadOnly}
                      readOnly={isReadOnly}
                      errors={formMethods.formState.errors.completion_date}
                      validation={{required: 'Completion date is required'}}
                    />
                    <ComptTextAreaField
                      name="description_reason"
                      label="Description and reason"
                      control={formMethods.control}
                      register={formMethods.register}
                      disabled={isReadOnly}
                      readOnly={isReadOnly}
                      validation={{required: 'Description and reason is required'}}
                      watchedValue={watchedDescriptionReason}
                      maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
                      errors={formMethods.formState.errors.description_reason}
                    />
                  </div>
                </fieldset>
              </form>
            </div>
          </ComptSidePanel.Content>
          <ComptSidePanel.Footer>
            <div className="flex justify-between w-full">
              {!props.editMode && (
                <>
                  {hasEditableStatus && (
                    <div className="flex gap-x-2">
                      <ComptButton
                        buttonType={ComptButtonType.PRIMARY}
                        onClick={onClickApprove}
                        disabled={isOverBudget}
                      >
                        Approve
                      </ComptButton>
                      <ComptButton
                        buttonType={ComptButtonType.SECONDARY}
                        onClick={() => props.setEditMode(true)}
                      >
                        Edit
                      </ComptButton>
                      <ComptButton
                        buttonType={ComptButtonType.DESTRUCTIVE}
                        onClick={() => setOpenRejectModal(true)}
                      >
                        Reject
                      </ComptButton>
                    </div>
                  )}
                  {!hasEditableStatus && (
                    <ComptButton
                      buttonType={ComptButtonType.SECONDARY}
                      onClick={() => {
                        setSearchParams({});
                      }}
                    >
                      Close
                    </ComptButton>
                  )}
                  <div className="flex">
                    <ComptButton
                      textClassName={`text-color-link ${
                        props.previousDisabled && 'text-color-body1 cursor-not-allowed'
                      }`}
                      buttonType={ComptButtonType.BORDERLESS}
                      disabled={props.previousDisabled}
                      onClick={props.onPreviousClick}
                      className="text-color"
                    >
                      Previous request
                    </ComptButton>
                    <ComptButton
                      textClassName={`text-color-link ${
                        props.nextDisabled && 'text-color-body1 cursor-not-allowed'
                      }`}
                      disabled={props.nextDisabled}
                      buttonType={ComptButtonType.BORDERLESS}
                      onClick={props.onNextClick}
                    >
                      Next request
                    </ComptButton>
                  </div>
                </>
              )}
              {props.editMode && (
                <div className="flex gap-x-2">
                  <ComptButton
                    onClick={formMethods.handleSubmit(onSubmit)}
                    disabled={isExpenseUpdating || isOverBudget}
                  >
                    Save
                  </ComptButton>
                  <ComptButton
                    buttonType={ComptButtonType.SECONDARY}
                    onClick={() => {
                      props.setEditMode(false);
                      formMethods.reset();
                    }}
                  >
                    Cancel
                  </ComptButton>
                </div>
              )}
            </div>
          </ComptSidePanel.Footer>
        </>
      )}
    </>
  );
};

enum TAX_CATEGORIES {
  'NON_TAXABLE' = 31,
  'NON_TAXABLE_WITH_LIMIT' = 4,
  'TAXABLE' = 30,
}

interface TaxCategoryOption {
  id: TAX_CATEGORIES;
  label: string;
  secondaryText?: string;
}

const taxCategoryOptions: TaxCategoryOption[] = [
  {
    id: TAX_CATEGORIES.NON_TAXABLE,
    label: 'Nontaxable (no limit)',
    secondaryText: 'A qualified working condition benefit (no limit)',
  },
  {
    id: TAX_CATEGORIES.NON_TAXABLE_WITH_LIMIT,
    label: 'Nontaxable ($5,250 annual limit)',
    secondaryText: 'Under an education assistance plan',
  },
  {
    id: TAX_CATEGORIES.TAXABLE,
    label: 'Taxable',
  },
];
