import React, {useState} from 'react';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {useFormStepperContext} from '@compt/common/compt-form-stepper/compt-form-stepper';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

// RTK queries
import {useUpdateLearningDevelopmentProgramMutation} from '@compt/app/services/api/learning-development-slice';

// Components
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ProgramFormFooter} from '@compt/pages/admin-pages/admin-learning-development/components/form-footer';
import {RequestFields} from '@compt/pages/admin-pages/admin-learning-development/components/program-creation-request-fields/request-fields';
import {
  RequestFormFieldValues,
  RequestFormSidePanel,
} from '@compt/pages/learning-page/components/request-form-side-panel/request-form-side-panel';
import {LearningDevelopmentStepper} from '../learning-development-stepper';

// Types
import {StepStatus} from '@compt/common/compt-progress-stepper/compt-progress-stepper';
import {LnDProgramForms} from '@compt/pages/admin-pages/admin-learning-development/learning-development.types';
import {
  LearningDevelopmentProgram,
  RequestFieldType,
  RequestType,
} from '@compt/types/learning-development/learning-development-program';

interface ReimbursementRequestFieldValues {
  field_name: string;
  supporting_text: string;
  field_type: RequestFieldType;
  sequence: number;
}

interface ReimbursementRequestFormProps {
  existingData: LearningDevelopmentProgram;
  readOnly: boolean;
}

export const ProgramCreationReimbursementRequestForm = (props: ReimbursementRequestFormProps) => {
  const navigate = useNavigate();
  const [showReimbursementRequestForm, setShowReimbursementRequestForm] = useState(false);

  const formMethods = useForm<ReimbursementRequestFieldValues>();
  const previewFormMethods = useForm<RequestFormFieldValues>();

  const {dirtyFields} = formMethods.formState;
  const [updateProgram, {isLoading: isUpdating}] = useUpdateLearningDevelopmentProgramMutation();

  const {existingData} = props;
  const requestFields = [...existingData.reimbursement_configuration.request_fields].sort(
    (a, b) => a.sequence - b.sequence,
  );

  const {setStepStatusMap, stepStatusBase} = useFormStepperContext();

  function onReimbursementRequestUpdate(
    form: ReimbursementRequestFieldValues,
    buttonCallback: (updatedData: LearningDevelopmentProgram) => void,
  ) {
    const updatedSubmission = {
      id: existingData.id,
      name: existingData.name,
      field_name: form.field_name,
      supporting_text: form.supporting_text,
      field_type: form.field_type,
      company: {
        id: existingData.company.id,
        name: existingData.company.name,
      },
    };

    updateProgram(updatedSubmission).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem updating your program',
          'Please try again.',
        );
        return;
      }

      buttonCallback(results.data);
    });
  }

  function onPreviousClick() {
    setStepStatusMap(() => ({
      ...stepStatusBase,
      [LnDProgramForms.PRE_APPROVAL_REQUEST]: StepStatus.CURRENT,
    }));
  }

  function onNextClick() {
    if (Object.keys(dirtyFields).length === 0) {
      setStepStatusMap((prevState) => ({
        ...prevState,
        [LnDProgramForms.REIMBURSEMENT_REQUEST]: StepStatus.COMPLETE,
        [LnDProgramForms.APPROVER_REVIEWER]: StepStatus.CURRENT,
      }));
      return;
    }

    formMethods.handleSubmit((form) =>
      onReimbursementRequestUpdate(form, () => {
        setStepStatusMap((prevState) => ({
          ...prevState,
          [LnDProgramForms.REIMBURSEMENT_REQUEST]: StepStatus.COMPLETE,
          [LnDProgramForms.APPROVER_REVIEWER]: StepStatus.CURRENT,
        }));
      }),
    )();
  }

  function onSaveDraftClick() {
    if (Object.keys(dirtyFields).length === 0) {
      if (existingData.is_draft) {
        triggerCustomToast('success', 'Program saved as a draft');
      }
      return;
    }

    formMethods.handleSubmit((form) => {
      onReimbursementRequestUpdate(form, (updatedData: LearningDevelopmentProgram) => {
        if (updatedData.is_draft) {
          triggerCustomToast('success', 'Program saved as a draft');
        }
      });
    })();
  }

  return (
    <>
      <RequestFormSidePanel
        id="admin-reimbursement-request-preview-form"
        title="Preview - Employee request form"
        requestType={RequestType.REIMBURSEMENT}
        open={showReimbursementRequestForm}
        setOpen={setShowReimbursementRequestForm}
        onSubmit={() => null}
        formMethods={previewFormMethods}
        program={props.existingData}
        requestFields={props.existingData.reimbursement_configuration.request_fields}
        isPreview
        showCommentsTab={false}
      />
      <div className="flex flex-col h-full pb-900 justify-between">
        <LearningDevelopmentStepper isFormDirty={Object.keys(dirtyFields).length > 0}>
          <div className="grid gap-y-2">
            <h3 className="text-color-body1">Reimbursement request form</h3>
            <p className="body1 text-color-body2">
              Define what information an employee will need to submit to make a request for
              reimbursement.
            </p>
          </div>
          <div className="grid pt-6 gap-y-4">
            <p className="body2 text-color-body1">
              Select which fields employees will need to input in order to request a reimbursement.
            </p>
            <p
              className="flex label3 text-color-link place-content-start items-center cursor-pointer"
              onClick={() => setShowReimbursementRequestForm(true)}
            >
              <ComptSvgIcon className="pr-2" iconName="eye-icon-link" />
              Preview employee form
            </p>
          </div>
          <div className="request-fields">
            <RequestFields requestFields={requestFields} onReorder={() => null} />
          </div>
        </LearningDevelopmentStepper>
        <ProgramFormFooter
          className="flex flex-shrink-0"
          isUpdating={isUpdating}
          readOnly={props.readOnly}
          onCloseCancel={() => {
            formMethods.reset();
            navigate('/manage-programs');
          }}
          onPreviousClick={onPreviousClick}
          onSaveDraftClick={onSaveDraftClick}
          onNextClick={onNextClick}
        />
      </div>
    </>
  );
};
