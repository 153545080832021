import React, {useContext, useEffect, useState} from 'react';

// RTK Queries
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetChannelCommentsQuery} from '@compt/app/services/api/lnd-comments-slice';

// Hooks and methods
import {FieldValues, UseFormReturn} from 'react-hook-form';
import toast from 'react-hot-toast';
import {ReceiptUploadContext} from '@compt/common/forms/compt-receipt-upload/receipt-upload-context';
import {calculateOverBudget} from '@compt/utils/lnd-budget-helpers';

// Types
import {DateString} from '@compt/types/common/date-string';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {
  LearningDevelopmentProgram,
  RequestField,
  RequestType,
} from '@compt/types/learning-development/learning-development-program';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';
import {ProgramSummary} from '@compt/types/learning-development/common-types';
import {
  EmployeePreApprovalRequest,
  getCustomFieldURLKey,
} from '@compt/types/learning-development/pre-approval-request';

// Components
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptReceiptFormCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-form-carousel';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {RequestFormContent} from './request-form-content';
import {ComptCommentBox} from '@compt/common/compt-comment-box/compt-comment-box';

export interface RequestFormProps {
  id: string;
  title: string;
  subtitle?: string;
  requestType: RequestType;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onSubmit: (payload: RequestFormFieldValues) => void;
  isPreview?: boolean;
  mutationLoading?: boolean;
  userId?: number;
  companyId?: number;
  userCountryCode?: SupportedCountriesType;
  'data-testid'?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMethods: UseFormReturn<RequestFormFieldValues, any, undefined>;
  program: ProgramSummary | LearningDevelopmentProgram;
  programCurrency?: string;
  maxAmount?: number;
  preApprovalRequest?: EmployeePreApprovalRequest;
  requestFields: RequestField[];
  onRequestDelete?: () => void;
  showCommentsTab?: boolean;
}

export interface RequestFormFieldValues extends FieldValues {
  receipt_key: string;
  ['Vendor name']: string;
  ['Description and reason']: string;
  ['Amount']: number;
  ['Expected completion date']?: DateString;
}

enum TabKeys {
  'REQUEST_OVERVIEW',
  'COMMENTS',
}

export const RequestFormSidePanel = ({showCommentsTab = true, ...props}: RequestFormProps) => {
  const {mutationLoading, open, setOpen, formMethods, requestType, preApprovalRequest} = props;
  const {receiptUploading, setReceiptUploading} = useContext(ReceiptUploadContext);
  const [activeTab, setActiveTab] = useState(TabKeys.REQUEST_OVERVIEW);
  const session = useGetSessionQuery();
  const isHijacked = session.data?.is_hijacked;

  const {data} = useGetChannelCommentsQuery(
    {channelId: preApprovalRequest?.comment_channel_id},
    {skip: !preApprovalRequest?.comment_channel_id || !showCommentsTab || !props.open},
  );

  // TODO: get Program data in COMPT-5697 (data structure from COMPT-5533).
  // const sortedRequestFields = requestFields.sort((a, b) => a.sequence - b.sequence);

  const watchedAmount = props.formMethods.watch('Amount');
  const overBudgetCalculation = calculateOverBudget(props.maxAmount, watchedAmount);
  // Only disable when over budget for reimbursement requests
  const isOverBudget = props.requestType === RequestType.REIMBURSEMENT && overBudgetCalculation > 0;

  // Remove any open toasts
  useEffect(() => {
    if (open) {
      toast.remove();
    }
  }, [open]);

  const receiptUrl: string =
    requestType === RequestType.PRE_APPROVAL && preApprovalRequest?.request_data
      ? (
          preApprovalRequest.request_data[getCustomFieldURLKey('Upload document(s)')] || ''
        ).toString()
      : '';

  return (
    <ComptSidePanel open={props.open} className="max-w-[920px]" data-testid={props['data-testid']}>
      <ComptSidePanel.Header
        title={props.title}
        subtitle={props.subtitle}
        setOpen={() => setOpen(false)}
        headerIcon={{id: 'file-icon-blue'}}
        formMethods={formMethods}
      />
      <div className="flex pt-300 px-400 gap-x-6">
        <button
          className={`py-2 text-lg font-medium ${
            activeTab === TabKeys.REQUEST_OVERVIEW
              ? 'border-b-2 border-[var(--Icon-icon-link,#3B7E95)] text-primary'
              : 'text-gray-500'
          }`}
          onClick={() => setActiveTab(TabKeys.REQUEST_OVERVIEW)}
        >
          <p className="heading4">Overview</p>
        </button>
        {!isHijacked && showCommentsTab && (
          <button
            className={`flex gap-2 py-2 text-lg font-medium ${
              activeTab === TabKeys.COMMENTS
                ? 'border-b-2 border-[var(--Icon-icon-link,#3B7E95)] text-primary'
                : 'text-gray-500'
            }`}
            onClick={() => setActiveTab(TabKeys.COMMENTS)}
          >
            <p className="heading4">Comments {data?.count ? `(${data?.count})` : null}</p>
          </button>
        )}
      </div>
      {activeTab === TabKeys.REQUEST_OVERVIEW ? (
        <>
          <ComptSidePanel.Content>
            <div className="h-full flex flex-col">
              <form className="grow" onSubmit={formMethods.handleSubmit(props.onSubmit)}>
                <fieldset
                  className="flex h-full grow"
                  disabled={formMethods.formState.isSubmitting}
                >
                  <div className="flex flex-col w-full md:flex-row">
                    <div className="flex justify-center bg-[#FAF6F1]">
                      <div
                        className={`
                      flex flex-col w-full sm:w-[480px] items-end p-6
                    `}
                      >
                        {/* TODO: attach correct domain to receipt field in COMPT-5729 */}
                        <ComptReceiptFormCarousel
                          formMethods={formMethods}
                          userId={props.userId}
                          receiptLabel="Upload document"
                          subLabel="Please upload supporting documents regarding the course."
                          initialSupportingDocs={[]}
                          readOnly={props.isPreview}
                          initialReceiptValue={receiptUrl}
                          receiptDomain="learning_and_dev"
                          supportingDocDomain="learning_and_dev_supporting_document"
                        />
                      </div>
                    </div>
                    <div className="w-full grow-[2] py-6 px-6">
                      <RequestFormContent
                        formMethods={formMethods}
                        requestFields={props.requestFields}
                        requestType={requestType}
                        isPreview={props.isPreview}
                        program={props.program}
                        preApprovalRequest={preApprovalRequest}
                        programCurrency={preApprovalRequest?.currency || props.programCurrency}
                        maxAmount={props.maxAmount}
                      />
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </ComptSidePanel.Content>
          <ComptSidePanel.Footer>
            <div className="flex sm:justify-start w-full bg-white">
              {props.isPreview ? (
                <>
                  <ComptButton
                    buttonType={ComptButtonType.PRIMARY}
                    onClick={formMethods.handleSubmit((payload) => props.onSubmit(payload))}
                    disabled={mutationLoading || receiptUploading || isOverBudget}
                    onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                      e.key === 'Enter' && e.preventDefault();
                    }}
                  >
                    {props.preApprovalRequest ? 'Save' : 'Request'}
                  </ComptButton>
                  <ComptButton
                    buttonType={ComptButtonType.OUTLINED}
                    onClick={() => {
                      setOpen(false);
                      formMethods.reset();
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                      e.key === 'Enter' && e.preventDefault();
                    }}
                  >
                    Close
                  </ComptButton>
                </>
              ) : (
                <div className="flex justify-between w-full">
                  <div className="flex gap-x-2">
                    <ComptButton
                      buttonType={ComptButtonType.PRIMARY}
                      onClick={formMethods.handleSubmit((payload) => props.onSubmit(payload))}
                      disabled={mutationLoading || receiptUploading}
                      onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                        e.key === 'Enter' && e.preventDefault();
                      }}
                    >
                      {props.preApprovalRequest ? 'Save' : 'Request'}
                    </ComptButton>
                    <ComptButton
                      buttonType={ComptButtonType.OUTLINED}
                      onClick={() => {
                        setOpen(false);
                        setReceiptUploading(() => false);
                        formMethods.reset();
                      }}
                      disabled={mutationLoading}
                    >
                      Cancel
                    </ComptButton>
                  </div>
                  {props.preApprovalRequest?.status ===
                    LearningRequestStatus.PRE_APPROVAL_REQUESTED &&
                    props.onRequestDelete && (
                      <ComptButton
                        buttonType={ComptButtonType.DESTRUCTIVE}
                        onClick={props.onRequestDelete}
                      >
                        Delete
                      </ComptButton>
                    )}
                </div>
              )}
            </div>
          </ComptSidePanel.Footer>
        </>
      ) : null}
      {activeTab === TabKeys.COMMENTS && preApprovalRequest?.comment_channel_id && !isHijacked ? (
        <ComptCommentBox
          channelId={preApprovalRequest.comment_channel_id}
          placeHolderText="Type your message..."
        />
      ) : null}
    </ComptSidePanel>
  );
};
