import React, {Dispatch, SetStateAction} from 'react';

// RTK queries
import {useCreateLearningReviewMutation} from '@compt/app/services/api/community-feed-slice';
import {useUpdateAccountSettingsMutation} from '@compt/app/services/api/accounts-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

// Types
import {ReviewFieldValues} from './review-field-values.types';
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';
import {AccountSettingsUpdate} from '@compt/types/account';

// Components
import {ReviewForm} from './review-form';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';

interface ReviewFormSidePanelProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  request: PreApprovalRequest;
  companyId: number | undefined;
  'data-testid'?: string;
}

export const ReviewFormSidePanel = (props: ReviewFormSidePanelProps) => {
  const formMethods = useForm<ReviewFieldValues>();

  const session = useGetSessionQuery();

  const [createLearningReview, {isLoading: learningReviewUpdating}] =
    useCreateLearningReviewMutation();
  const [updateAccountSettings, {isLoading: linkedinAccountUpdating}] =
    useUpdateAccountSettingsMutation();

  function onSubmit(form: ReviewFieldValues) {
    if (!props.companyId || !props.request.id) return;

    const submission = {
      request_id: props.request.id,
      review_title: form.describe_purchased,
      course_url: form.course_url,
      review_description: form.rating_detail,
      rating: form.rating_number,
      user_entered_keywords: form.keywords ?? [],
    };

    createLearningReview({companyId: props.companyId, body: submission}).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem submitting this learning review',
          'Please try again',
        );
        return;
      }

      triggerCustomToast('success', 'Successfully submitted learning review');
      formMethods.reset();
      props.setOpen(false);
    });

    const linkedinSubmission = {linkedin_account: form.linkedin_account} as AccountSettingsUpdate;
    updateAccountSettings(linkedinSubmission).then((results) => {
      if ('error' in results) {
        triggerCustomToast('error', 'There was a problem submitting your linkedin account');
        return;
      }
      form.reset();
    });
  }

  function onCancel() {
    if (learningReviewUpdating || linkedinAccountUpdating) return;
    formMethods.reset();
    props.setOpen(false);
  }

  return (
    <ComptSidePanel open={props.open} className="max-w-[520px]" data-testid={props['data-testid']}>
      <ComptSidePanel.Header
        title="Reimbursement form"
        subtitle={props.request.program.name}
        setOpen={onCancel}
        headerIcon={{id: 'file-icon-blue'}}
        breadCrumbs={['Reimbursement request', 'Your review (optional)']}
      />
      <ComptSidePanel.Content className="sm:overflow-y-auto p-6">
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <p className="label2 text-color-heading">Your review</p>
          <p className="body3 text-color-body2 mb-6">
            Review your experience with the purchase. This will be shared publicly on the Community
            page. Responses are anonymous.
          </p>
          <ReviewForm formMethods={formMethods} />
          {!session.data?.linkedin_account && (
            <>
              <p className="label2 text-color-heading">Show your LinkedIn profile</p>
              <p className="body3 text-color-body2 mb-6">
                Add your LinkedIn URL if you would like your profile to be visible to other Compt
                users (across companies) on the Community feed.
              </p>
              <ComptTextField
                name="linkedin_account"
                label="LinkedIn URL"
                placeholder={'https://'}
                register={formMethods.register}
                control={formMethods.control}
                errors={formMethods.formState.errors['linkedin_account']}
                validation={{
                  pattern: {
                    value:
                      /^(https?:\/\/)?(www\.)?([a-zA-Z]{2}\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/,
                    message: 'Please enter a valid LinkedIn public profile URL.',
                  },
                }}
              />
              <p className="body3 text-color-body2 -mt-3">
                By adding your LinkedIn URL, your review will no longer be anonymous.
              </p>
            </>
          )}
        </form>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="flex">
          <ComptButton
            type="submit"
            onClick={formMethods.handleSubmit(onSubmit)}
            disabled={learningReviewUpdating || linkedinAccountUpdating}
          >
            Submit
          </ComptButton>
          <ComptButton
            buttonType={ComptButtonType.BORDERLESS}
            onClick={onCancel}
            disabled={learningReviewUpdating || linkedinAccountUpdating}
          >
            Skip
          </ComptButton>
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};
