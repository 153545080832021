import React from 'react';

// Hooks and methods
import {formatCurrency} from '@compt/utils/international-helpers';
import DOMPurify from 'dompurify';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';

// Types
import {EmployeePreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptRejectionReason} from '@compt/common/compt-rejection-reason/compt-rejection-reason';
import {FileExtensionButton} from '@compt/common/compt-file-extension-button/compt-file-extension-button';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ProgramActivity} from './program-activity';
import {RequestDataTable} from './request-data-table';

export interface ReimbursementSidePanelDetailsTabProps {
  preApprovalRequest: EmployeePreApprovalRequest;
  preApprovalRequestReceiptUrl: string;
  onCloseClicked: () => void;
}

export const ReimbursementSidePanelDetailsTab = ({
  preApprovalRequest,
  preApprovalRequestReceiptUrl,
  onCloseClicked,
}: ReimbursementSidePanelDetailsTabProps) => (
  <>
    <ComptSidePanel.Content className="sm:overflow-y-auto">
      <div className="h-full flex flex-col p-6">
        <div>
          <h4>{preApprovalRequest.program.name}</h4>
          <p
            className="body3 text-color-body1 inner-html"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(preApprovalRequest.program.description),
            }}
          />
        </div>
        <hr className="my-6 border-gray-300" />
        <ProgramActivity preApprovalRequest={preApprovalRequest} />

        <div>
          <div className="flex flex-row gap-x-2 items-center mb-4">
            <ComptSvgIcon iconName="message-text-icon" />
            <h4>Request form</h4>
          </div>
          {preApprovalRequest.rejection_reason && (
            <ComptRejectionReason rejectionReason={preApprovalRequest.rejection_reason} />
          )}
          <RequestDataTable
            configurationFields={preApprovalRequest?.pre_approval_configuration.request_fields}
            data={preApprovalRequest?.request_data}
            currency={preApprovalRequest.currency}
          />
          {preApprovalRequest.expense && (
            <>
              <div className="flex flex-row gap-x-2 items-center mb-4">
                <ComptSvgIcon iconName="message-text-icon" />
                <h4>Reimbursement form</h4>
              </div>
              {preApprovalRequest.expense.rejection_reason && (
                <ComptRejectionReason
                  rejectionReason={preApprovalRequest.expense.rejection_reason}
                />
              )}
              <div className="flex flex-row">
                <div className="flex flex-col gap-y-2 items-start mb-4 basis-44">
                  <span className="text-color-body1">
                    <p className="label3">Items</p>
                  </span>
                  <span className="border-b w-full pb-2">
                    <p className="body2">Vendor</p>
                  </span>
                  <span className="border-b w-full pb-2">
                    <p className="body2">Amount</p>
                  </span>
                  <span className="border-b w-full pb-2">
                    <p className="body2">Date of expense</p>
                  </span>
                  <span className="w-full pb-2 pr-2">
                    <p className="body2">Description and reasons</p>
                  </span>
                </div>
                <div className="flex flex-col gap-y-2 items-start mb-4 basis-72">
                  <span className="text-color-body1">
                    <p className="label3">Selection</p>
                  </span>
                  <span className="border-b w-full pb-2">
                    <p className="body2">{preApprovalRequest.expense.vendor_name}</p>
                  </span>
                  <span className="border-b w-full pb-2">
                    <p className="body2">
                      {formatCurrency(
                        preApprovalRequest.expense.amount_of_expense,
                        preApprovalRequest.currency,
                      )}
                    </p>
                  </span>
                  <span className="border-b w-full pb-2">
                    <p className="body2">
                      {formattedDate(
                        preApprovalRequest.expense.date_of_expense,
                        DATE_FORMAT_OPTION['mm/dd/yyyy'],
                      )}
                    </p>
                  </span>
                  <span className="w-full pb-2">
                    <p className="body2">{preApprovalRequest.expense.description}</p>
                    <br />
                  </span>
                </div>
              </div>
              <hr className="mb-6" />
            </>
          )}
          <div className="pb-4">
            <div className="flex flex-row gap-x-2 items-center mb-4">
              <ComptSvgIcon iconName="upload-cloud-icon" />
              <h4>Request form documents</h4>
            </div>
            <div className="grid gap-y-2">
              <FileExtensionButton fileUrl={preApprovalRequestReceiptUrl} />
              {preApprovalRequest.expense && (
                <>
                  <div className="flex flex-row gap-x-2 items-center mt-6 pt-6 mb-3 border-t">
                    <ComptSvgIcon iconName="upload-cloud-icon" />
                    <h4>Reimbursement form documents</h4>
                  </div>
                  <FileExtensionButton fileUrl={preApprovalRequest.expense.receipt_image} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ComptSidePanel.Content>
    <ComptSidePanel.Footer>
      <ComptButton
        buttonType={ComptButtonType.OUTLINED}
        onClick={onCloseClicked}
        onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
          e.key === 'Enter' && e.preventDefault();
        }}
      >
        Close
      </ComptButton>
    </ComptSidePanel.Footer>
  </>
);
