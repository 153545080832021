import React, {useState} from 'react';

// Components
import {ComptDatePicker} from '../forms/compt-date-picker/compt-date-picker';

interface ComptDateRangeFilterProps {
  label: string;
  currentValues: Record<string, Date | string>;
  handleChange: (dateObject: Record<string, Date | string>) => void;
}

export const DateRangeFilter = (props: ComptDateRangeFilterProps) => {
  const [startDate, setStartDate] = useState<Date | string>(props.currentValues?.start ?? '');
  const [endDate, setEndDate] = useState<Date | string>(props.currentValues?.end ?? '');

  return (
    <div className="relative z-10 flex items-center mr-5">
      <div>
        <ComptDatePicker
          endDate={endDate}
          datePickerClassName="w-72 right-0"
          value={props.currentValues.start}
          showClearButton
          propagateClear
          onChange={(date) => {
            setStartDate(date);
            props.handleChange({...props.currentValues, start: date});
          }}
          onClearButtonClick={() => {
            setStartDate('');
            props.handleChange({...props.currentValues, start: ''});
          }}
        />
      </div>
      <span className="text-center mx-2">-</span>
      <div>
        <ComptDatePicker
          startDate={startDate}
          datePickerClassName="w-72 right-0"
          value={props.currentValues.end}
          showClearButton
          propagateClear
          onChange={(date) => {
            setEndDate(date);
            props.handleChange({...props.currentValues, end: date});
          }}
          onClearButtonClick={() => {
            setEndDate('');
            props.handleChange({...props.currentValues, end: ''});
          }}
        />
      </div>
    </div>
  );
};
