import React, {useEffect, useState} from 'react';

// RTK queries
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetEmployeePreApprovalRequestQuery} from '@compt/app/services/api/employee-learning-development-slice';
import {useGetChannelCommentsQuery} from '@compt/app/services/api/lnd-comments-slice';

// Hooks and methods
import {useSearchParams} from 'react-router-dom';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {getCustomFieldURLKey} from '@compt/types/learning-development/pre-approval-request';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ReviewFormTab} from './review-forms/review-form-tab';
import {ReimbursementSidePanelDetailsTab} from '@compt/pages/learning-page/components/reimbursement-side-panel-details-tab';
import {ComptCommentBox} from '@compt/common/compt-comment-box/compt-comment-box';

interface ReimbursementSidePanelProps {
  requestId: string | null;
  companyId: number;
  'data-testid'?: string;
}

enum TabKeys {
  'REQUEST_DETAILS',
  'COMMENTS',
  'REVIEW',
}

export const ReimbursementSidePanel = (props: ReimbursementSidePanelProps) => {
  const [, setSearchParams] = useSearchParams();

  const preApprovalRequestQuery = useGetEmployeePreApprovalRequestQuery(
    {
      companyId: props.companyId,
      requestId: props.requestId,
    },
    {skip: !props.companyId || !props.requestId},
  );

  const preApprovalRequest = preApprovalRequestQuery.data;

  const {data} = useGetChannelCommentsQuery(
    {channelId: preApprovalRequest?.comment_channel_id},
    {skip: !preApprovalRequest?.comment_channel_id},
  );

  function clearSelectedRequest() {
    setSearchParams({});
  }

  const preApprovalRequestReceiptUrl: string = (
    preApprovalRequest?.request_data[getCustomFieldURLKey('Upload document(s)')] || ''
  ).toString();

  const vendorName = preApprovalRequest?.request_data['Vendor name'];
  const [activeTab, setActiveTab] = useState(TabKeys.REQUEST_DETAILS);
  const session = useGetSessionQuery();
  const isHijacked = session.data?.is_hijacked;

  // Reset tab state when opening different requests
  useEffect(() => {
    setActiveTab(TabKeys.REQUEST_DETAILS);
  }, [props.requestId]);

  return (
    <>
      <ComptSidePanel
        open={!!props.requestId}
        className="max-w-[520px]"
        data-testid={props['data-testid']}
      >
        <ComptLoadingIndicator
          isLoading={preApprovalRequestQuery.isLoading || preApprovalRequestQuery.isFetching}
        >
          {preApprovalRequest && (
            <>
              <ComptSidePanel.Header
                title={vendorName || ''}
                setOpen={clearSelectedRequest}
                headerIcon={{id: 'file-icon-blue'}}
              />
              <div className="flex pt-300 px-400 gap-x-6">
                <button
                  className={`py-2 text-lg font-medium ${
                    activeTab === TabKeys.REQUEST_DETAILS
                      ? 'border-b-2 border-[var(--Icon-icon-link,#3B7E95)] text-primary'
                      : 'text-gray-500'
                  }`}
                  onClick={() => setActiveTab(TabKeys.REQUEST_DETAILS)}
                >
                  <p className="heading4">Overview</p>
                </button>
                {!isHijacked && (
                  <button
                    className={`flex gap-2 py-2 text-lg font-medium ${
                      activeTab === TabKeys.COMMENTS
                        ? 'border-b-2 border-[var(--Icon-icon-link,#3B7E95)] text-primary'
                        : 'text-gray-500'
                    }`}
                    onClick={() => setActiveTab(TabKeys.COMMENTS)}
                  >
                    <p className="heading4">Comments {data?.count ? `(${data?.count})` : null}</p>
                  </button>
                )}
                {!isHijacked &&
                  preApprovalRequest.employee_review &&
                  featureEnabled(FeatureFlags.COMMUNITY_FEED) && (
                    <button
                      className={`py-2 text-lg font-medium ${
                        activeTab === TabKeys.REVIEW
                          ? 'border-b-2 border-[var(--Icon-icon-link,#3B7E95)] text-primary'
                          : 'text-gray-500'
                      }`}
                      onClick={() => setActiveTab(TabKeys.REVIEW)}
                    >
                      <p className="heading4">Review</p>
                    </button>
                  )}
              </div>
              {activeTab === TabKeys.REQUEST_DETAILS ? (
                <>
                  <ReimbursementSidePanelDetailsTab
                    preApprovalRequest={preApprovalRequest}
                    preApprovalRequestReceiptUrl={preApprovalRequestReceiptUrl}
                    onCloseClicked={clearSelectedRequest}
                  />
                </>
              ) : null}

              {activeTab === TabKeys.COMMENTS &&
              preApprovalRequest?.comment_channel_id &&
              !isHijacked ? (
                <>
                  <ComptCommentBox
                    channelId={preApprovalRequest.comment_channel_id}
                    placeHolderText="Type your message..."
                  />
                </>
              ) : null}
              {activeTab === TabKeys.REVIEW && !isHijacked && preApprovalRequest.employee_review ? (
                <ReviewFormTab
                  employeeReview={preApprovalRequest.employee_review}
                  employeeSession={session.data}
                />
              ) : null}
            </>
          )}
        </ComptLoadingIndicator>
      </ComptSidePanel>
    </>
  );
};
