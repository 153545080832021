import React, {useState, useRef} from 'react';

// Hooks and methods
import {industryFromId} from '@compt/types/industry';

// Types
import {LearningCourseReview, LearningReviewReaction} from '@compt/types/community-feed';
import {PerkCategory} from '@compt/types/perk-category';
import {ComptTooltipPlacement} from '../compt-tooltip/compt-tooltip.controller';

// Components
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {ComptTooltip} from '../compt-tooltip/compt-tooltip.container';
import {
  useCreateReviewReactionMutation,
  useDeleteReviewReactionMutation,
  useUpdateReviewReactionMutation,
} from '@compt/app/services/api/community-feed-slice';

interface ComptCommunityCardProps {
  review: LearningCourseReview;
}

export const ComptCommunityCard = ({review}: ComptCommunityCardProps) => {
  const [openReactionModal, setOpenReactionModal] = useState(false);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [createReaction] = useCreateReviewReactionMutation();
  const [updateReaction] = useUpdateReviewReactionMutation();
  const [deleteReaction] = useDeleteReviewReactionMutation();

  function ensureAbsoluteUrl(url: string) {
    // Check if the URL starts with a protocol
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  }

  const perkCategories: PerkCategory[] = [
    {
      id: 7,
      name: 'Professional Development',
      internal_id: 'professional-development',
      taxable: true,
      commuter: false,
      description: 'Care for the special animal in your life (insurance, food, grooming, etc.)',
    },
  ];

  const clearTimer = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
  };

  const handleMouseEnter = () => {
    // Clear any pending timeouts
    clearTimer();
    setOpenReactionModal(true);
  };

  const handleMouseLeave = () => {
    clearTimer();
    hoverTimeoutRef.current = setTimeout(() => {
      setOpenReactionModal(false);
    }, 300);
  };

  const handleReactionClick = (reaction: LearningReviewReaction) => {
    if (review.user_reaction?.id) {
      if (review.user_reaction.reaction_type === reaction) {
        deleteReaction({
          review_id: review.id,
          reaction_id: review.user_reaction.id,
          body: {reaction_type: reaction},
        });
      } else {
        updateReaction({
          review_id: review.id,
          reaction_id: review.user_reaction.id,
          body: {reaction_type: reaction},
        });
      }
    } else {
      createReaction({review_id: review.id, body: {reaction_type: reaction}});
    }
    handleMouseLeave();
  };

  return (
    <div className="bg-surface-tint p-[12px] rounded-xl border border-stroke-divider-1">
      <div className="flex">
        <div
          className="flex items-center justify-center bg-white rounded-full h-[40px] w-[40px] 
        mr-4 border border-stroke-divider-1 flex-shrink-0"
        >
          <ComptSvgIcon
            iconName={`rating-${review.rating}`}
            svgProp={{
              width: '20px',
              height: '20px',
            }}
          />
        </div>
        <div className="w-full">
          <div className="flex gap-1">
            <p className="label4">
              <span className="capitalize">{review.authors_title_when_submitted || 'Someone'}</span>
              <span> in {industryFromId(review.authors_industry)?.toLowerCase()}</span>
            </p>
            {review.authors_linkedin_account ? (
              <a
                href={ensureAbsoluteUrl(review.course_url)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ComptSvgIcon
                  iconName={'linkedin-icon'}
                  svgProp={{
                    width: '16px',
                    height: '16px',
                  }}
                />
              </a>
            ) : null}
          </div>
          <div className="flex items-center gap-1">
            <a
              className="label3 text-color-link cursor-pointer"
              href={ensureAbsoluteUrl(review.course_url)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {review.review_title}
            </a>
            {perkCategories && perkCategories.length > 0 && (
              <ComptTooltip
                placement={ComptTooltipPlacement.BOTTOM}
                message={
                  <div className="compt-text--xs text-white">
                    {perkCategories?.map((category, index) => <p key={index}>{category.name}</p>)}
                  </div>
                }
              >
                <div className="flex -space-x-2">
                  {perkCategories.map(({internal_id, id}) => (
                    <ComptSvgIcon
                      key={id}
                      iconName={internal_id}
                      isCategoryIcon
                      svgProp={{width: '20px', height: '20px'}}
                      className="p-1 h-6 w-6 border border-stroke-divider-1  bg-[white] rounded-full
            flex items-center justify-center"
                    />
                  ))}
                </div>
              </ComptTooltip>
            )}
          </div>
          <div className="flex w-full relative">
            <div
              className="flex flex-col w-full bg-white mt-2 border border-stroke-divider-1
                rounded-xl p-4 mr-2 "
            >
              {review.rating === 5 && (
                <p className="label4 text-color-body1 mb-2">Highly recommended</p>
              )}
              <p className="body3 text-color-body1">{`"${review.review_description}"`}</p>
            </div>
            <div
              className={`relative flex flex-col items-center justify-center mt-2 border 
                  border-stroke-divider-1 rounded-lg p-4 w-[60px] ${
                    openReactionModal ? 'bg-surface-secondary-hover' : 'bg-white'
                  }`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {openReactionModal && (
                <div
                  className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 flex
                  bg-white border border-stroke-divider-1 rounded-full p-1 gap-1 shadow-lg"
                >
                  {Object.values(LearningReviewReaction).map((reaction) => (
                    <button
                      key={reaction}
                      className="reaction-button"
                      onClick={() => handleReactionClick(reaction)}
                      aria-label={`React with ${reaction.toLowerCase()}`}
                    >
                      <ComptSvgIcon
                        iconName={`${reaction.toLowerCase()}-icon`}
                        svgProp={{
                          width: '40px',
                          height: '40px',
                        }}
                      />
                    </button>
                  ))}
                </div>
              )}

              <ComptSvgIcon
                iconName={
                  review.user_reaction?.reaction_type
                    ? `${review.user_reaction.reaction_type.toLowerCase()}-icon`
                    : 'chevron-up-icon-filled'
                }
                svgProp={{
                  width: '12px',
                  height: '12px',
                }}
              />
              <p className="label3 text-color-black">{review.total_reactions}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
